import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';

interface Order {
  id: string;
  customer_id: string;
  total_amount: number;
  payment_method: string;
  status: string;
  tracking_status: string;
  created_at: string;
  items: CartItem[];
}

interface CartItem {
  product_id: string;
  product_name?: string; // Optional until loaded
  quantity: number;
  price: number;
  total_price: number;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const OrderList: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  useEffect(() => {
    fetch('https://warunggo.bisalewat.online/api/orders', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setOrders(data))
      .catch((error) => console.error('Error fetching orders:', error));
  }, []);

  const handleDetailClick = async (order: Order) => {
    // Fetch product names based on product_id
    const itemsWithNames = await Promise.all(order.items.map(async (item) => {
      const response = await fetch(`https://warunggo.bisalewat.online/api/products/${item.product_id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      const productData = await response.json();
      return {
        ...item,
        product_name: productData.name, // Assuming response has product name
      };
    }));

    setSelectedOrder({ ...order, items: itemsWithNames });
    setIsDetailOpen(true);
  };

  const closeDetailPopup = () => {
    setIsDetailOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div className="flex flex-col min-h-screen pb-10">
      <Header />
      <div className="flex-grow p-4">
        <h1 className="text-2xl font-bold mb-4">Order List</h1>
        <div className="bg-white shadow-md rounded-lg">
          {orders.map((order) => (
            <div key={order.id} className="border-b p-4 grid grid-cols-2 gap-4 items-start">
              <div>
                <div className="font-semibold text-lg">Order ID: {order.id}</div>
                <div className="text-sm text-gray-500">Tanggal: {formatDate(order.created_at)}</div>
                <div className="text-sm">Total: Rp{order.total_amount.toLocaleString()}</div>
                <div className="text-sm">Status: {order.status}</div>
                <div className="text-sm">Pengiriman: {order.tracking_status}</div>
              </div>
              <div className="text-right">
                <button
                  onClick={() => handleDetailClick(order)}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 transition ease-in-out duration-300"
                >
                  Lihat Detail
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Navbar />

      {isDetailOpen && selectedOrder && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-4/5 md:w-1/2">
            <h2 className="text-xl font-semibold mb-4">Detail Pesanan {selectedOrder.id}</h2>
            <div>
              {selectedOrder.items.map((item) => (
                <div key={item.product_id} className="mb-4">
                  <div className="font-semibold">Produk: {item.product_name || item.product_id}</div>
                  <div className="text-sm">Jumlah: {item.quantity}</div>
                  <div className="text-sm">Harga Satuan: Rp{item.price.toLocaleString()}</div>
                  <div className="text-sm">Total Harga: Rp{item.total_price.toLocaleString()}</div>
                </div>
              ))}
            </div>
            <div className="text-right font-bold mt-4">
              Total Pesanan: Rp{selectedOrder.total_amount.toLocaleString()}
            </div>
            <div className="mt-6 text-right">
              <button
                onClick={closeDetailPopup}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderList;
