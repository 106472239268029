// src/components/BannerSlider.tsx
import React, { useState, useEffect } from 'react';

const BannerSlider: React.FC = () => {
  const banners = ['/images/banner_1.jpg', '/images/banner_2.jpg', '/images/banner_3.jpg'];
  const [currentBanner, setCurrentBanner] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className="relative flex items-center justify-center w-full h-auto">
      <img
        src={banners[currentBanner]}
        alt="Banner"
        className="w-full max-w-auto h-auto max-h-100 object-cover rounded"
      />
    </div>
  );
};

export default BannerSlider;
