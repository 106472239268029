// src/components/PublicRoute.tsx
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

interface PublicRouteProps {
  element: React.ReactNode;
  isAuthenticated: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element, isAuthenticated }) => {
  return !isAuthenticated ? <>{element}</> : <Navigate to="/" replace />;
};

export default PublicRoute;
