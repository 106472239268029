// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import LoginForm from '../components/LoginForm';

const Login: React.FC = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Start fade-out effect after a delay
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true);
    }, 2500); // Adjust this if needed

    // Hide splash screen and show login form after the splash screen duration
    const finishTimer = setTimeout(() => {
      setShowSplash(false);
    }, 3000); // Duration should match your splash screen timing

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(finishTimer);
    };
  }, []);

  return (
    <>
      {showSplash ? (
        <div
          className={`flex items-center justify-center min-h-screen bg-red-500 transition-opacity duration-1000 ${
            fadeOut ? 'opacity-0' : 'opacity-100'
          }`}
        >
          <img src="/images/logo.png" alt="Logo" className="h-16 object-contain animate-pulse" />
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <LoginForm />
        </div>
      )}
    </>
  );
};

export default Login;