// src/components/Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <nav className="bg-red-500 p-1 rounded-b-3xl">
      <div className="container mx-auto flex justify-center items-center">
        <Link to="/">
          <img 
            src="/images/logo.png" 
            alt="WarungGo Logo" 
            className="h-12 object-contain" // Menyesuaikan ukuran tinggi sesuai header
          />
        </Link>
        {/* Tambahkan tautan navigasi lainnya di sini jika diperlukan */}
      </div>
    </nav>
  );
};

export default Header;
