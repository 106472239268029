// src/components/Navbar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
const FiHome = require('react-icons/fi').FiHome;
const FiList = require('react-icons/fi').FiList;
const FiUser = require('react-icons/fi').FiUser;

const Navbar: React.FC = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-red-500 shadow-lg p-1 rounded-t-3xl">
      <div className="flex justify-around text-white">
        <Link to="/" className="flex flex-col items-center">
          <FiHome size={18} />
          <span>Beranda</span>
        </Link>
        <Link to="/order-list" className="flex flex-col items-center">
          <FiList size={18} />
          <span>Order List</span>
        </Link>
        <Link to="/profile" className="flex flex-col items-center">
          <FiUser size={18} />
          <span>Profil</span>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
