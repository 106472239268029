// src/pages/UpdateProfile.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import axios from 'axios';
import Modal from 'react-modal';

// Import custom icons
import markerIcon from '../styles/map/marker-icon.png';
import markerIcon2x from '../styles/map/marker-icon-2x.png';
import markerShadow from '../styles/map/marker-shadow.png';
// import layersIcon from '../styles/map/layers.png';
// import layersIcon2x from '../styles/map/layers-2x.png';

// Set custom marker icon with retina support
const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41], // Ukuran icon marker
  iconAnchor: [12, 41], // Titik anchor relatif terhadap icon
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker: React.FC<{ setPosition: React.Dispatch<React.SetStateAction<[number, number] | null>> }> = ({ setPosition }) => {
  useMapEvents({
    click(e: L.LeafletMouseEvent) {
      setPosition([e.latlng.lat, e.latlng.lng]);
    },
  });
  return null;
};

const UpdateProfile: React.FC = () => {
  const [profile, setProfile] = useState({
    store_name: '',
    store_address: '',
    owner_name: '',
    email: '',
    whatsapp_number: '',
    latitude: 51.505,
    longitude: -0.09,
  });
  const [position, setPosition] = useState<[number, number] | null>(null);
  const [showMap, setShowMap] = useState(false);
  const [tempPosition, setTempPosition] = useState<[number, number] | null>(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (token) {
          const res = await axios.get('https://warunggo.bisalewat.online/api/profile', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = res.data;
          setProfile({
            store_name: data.store_name,
            store_address: data.store_address,
            owner_name: data.owner_name,
            email: data.email,
            whatsapp_number: data.whatsapp_number,
            latitude: data.latitude || 51.505,
            longitude: data.longitude || -0.09,
          });
          setPosition([data.latitude || 51.505, data.longitude || -0.09]);
        } else {
          setError('No authentication token found');
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchProfile();
  }, []);

  const handleSubmit = async () => {
    try {
      const updatedProfile = {
        ...profile,
        latitude: position ? position[0] : profile.latitude,
        longitude: position ? position[1] : profile.longitude,
      };
      if (token) {
        await axios.put('https://warunggo.bisalewat.online/api/profile/updateCustomer', updatedProfile, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        });
      navigate('/profile');
      } else {
          setError('No authentication token found');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSetNewAddress = () => {
    if (tempPosition) {
      setPosition(tempPosition);
    }
    setShowMap(false);
  };

  const handleResetAddress = () => {
    setTempPosition([profile.latitude, profile.longitude]);
    setPosition([profile.latitude, profile.longitude]);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow container mx-auto p-6">
        <h1 className="text-2xl font-bold mb-4">Update Profile</h1>
        {/* Form Fields */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Store Name</label>
          <input
            type="text"
            value={profile.store_name}
            onChange={(e) => setProfile({ ...profile, store_name: e.target.value })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Store Address</label>
          <input
            type="text"
            value={profile.store_address}
            onChange={(e) => setProfile({ ...profile, store_address: e.target.value })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Owner Name</label>
          <input
            type="text"
            value={profile.owner_name}
            onChange={(e) => setProfile({ ...profile, owner_name: e.target.value })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
          <input
            type="email"
            value={profile.email}
            onChange={(e) => setProfile({ ...profile, email: e.target.value })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">WhatsApp Number</label>
          <input
            type="text"
            value={profile.whatsapp_number}
            onChange={(e) => setProfile({ ...profile, whatsapp_number: e.target.value })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => {
              setTempPosition(position);
              setShowMap(true);
            }}
            className="bg-blue-500 text-white py-1.5 px-3 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mt-4"
          >
            Pilih Titik Toko
          </button>
          <Modal
            isOpen={showMap}
            onRequestClose={() => setShowMap(false)}
            contentLabel="Select Location"
            ariaHideApp={false}
            className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
          >
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-4">
              <div className="bg-white p-6">
                <MapContainer
                  center={tempPosition || [profile.latitude, profile.longitude]}
                  zoom={13}
                  style={{ height: '400px', width: '100%' }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {tempPosition && <Marker position={tempPosition} />}
                  <LocationMarker setPosition={setTempPosition} />
                </MapContainer>
                <div className="flex justify-between mt-4 space-x-2">
                  <button
                    onClick={handleSetNewAddress}
                    className="bg-green-500 text-white py-1.5 px-3 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Set Titik Baru
                  </button>
                  <button
                    onClick={handleResetAddress}
                    className="bg-yellow-500 text-white py-1.5 px-3 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                  >
                    Titik Semula
                  </button>
                  <button
                    onClick={() => setShowMap(false)}
                    className="bg-red-500 text-white py-1.5 px-3 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white py-1.5 px-3 rounded-md  hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mt-4"
          >
            Perbarui Data
          </button>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default UpdateProfile;
