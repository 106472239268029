// src/utils/auth.ts
export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('authToken');
  const expirationTime = localStorage.getItem('expirationTime');

  if (!token || !expirationTime) {
    return false;
  }

  const currentTime = Date.now();

  if (currentTime >= Number(expirationTime)) {
    localStorage.removeItem('authToken');
    localStorage.removeItem('expirationTime');
    return false;
  }

  return true;
};

