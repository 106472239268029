import React from 'react';
import { Link } from 'react-router-dom';
const FiSearch = require('react-icons/fi').FiSearch;
const FiShoppingCart = require('react-icons/fi').FiShoppingCart;
const FiBell = require('react-icons/fi').FiBell;

interface SearchBarProps {
  onSearch: (query: string) => void;
  cartItemCount: number;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, cartItemCount }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <div className="p-2">
      <div className="flex items-center justify-center"> {/* Centering the search bar and icons */}
        <div className="flex-grow relative mx-2"> {/* Added margin on the left and right */}
          <input
            type="text"
            placeholder="Cari produk..."
            onChange={handleInputChange}
            className="w-full p-2 pl-10 bg-gray-100 text-gray-700 rounded-full focus:outline-none"
          />
          <FiSearch className="absolute left-3 top-3 text-gray-500" />
        </div>
        <div className="flex items-center">
          <Link to="/notifications" className="relative mr-2"> {/* Added margin on the right */}
            <FiBell className="text-gray-600 text-2xl" />
            {/* Notifikasi badge */}
          </Link>
          <Link to="/cart" className="relative">
            <FiShoppingCart className="text-gray-600 text-2xl mr-2" />
            {cartItemCount > 0 && (
              <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs rounded-full px-2 py-0.5 mr-2">
                {cartItemCount}
              </span>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;