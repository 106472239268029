// src/pages/Profile
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Navbar from '../components/Navbar';

interface UserProfile {
  username: string;
  email: string;
  store_name: string;
  store_address: string;
  owner_name: string;
  ktp_number: string;
  whatsapp_number: string;
}

const Profile: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (token) {
          const response = await axios.get('https://warunggo.bisalewat.online/api/profile', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setProfile(response.data);
        } else {
          setError('No authentication token found');
        }
      } catch (error) {
        setError('Failed to load profile');
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleUpdateProfile = () => {
    navigate('/update-profile');
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login', { replace: true });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow container mx-auto p-6">
        <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-2xl font-bold mb-4">Profile</h1>
          {profile && (
            <div>
              <p><strong>Username:</strong> {profile.username}</p>
              <p><strong>Email:</strong> {profile.email}</p>
              <p><strong>Store Name:</strong> {profile.store_name}</p>
              <p><strong>Store Address:</strong> {profile.store_address}</p>
              <p><strong>Owner Name:</strong> {profile.owner_name}</p>
              <p><strong>KTP Number:</strong> {profile.ktp_number}</p>
              <p><strong>WhatsApp Number:</strong> {profile.whatsapp_number}</p>
            </div>
          )}
          <button
            onClick={handleUpdateProfile}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mt-4"
          >
            Update Profile
          </button>
          <button
            onClick={handleLogout}
            className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-4"
          >
            Logout
          </button>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default Profile;
