import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import BannerSlider from '../components/BannerSlider';
import ProductCard from '../components/ProductCard';
import ProductDetailModal from '../components/ProductDetailModal';
import SearchBar from '../components/SearchBar';
import axios from 'axios';

interface Product {
  id: string;
  name: string;
  category_id: string;
  price: number;
  discount_price?: number;
  quantity: number;
  is_active: boolean;
  image: string;
  description: string;
}

interface CartItem {
  id: string;
  name: string;
  price: number;
  discount_price?: number;
  image: string;
  quantity: number;
}

// Helper function to load cart from localStorage
const loadCartFromLocalStorage = (): CartItem[] => {
  const savedCart = localStorage.getItem('cart');
  if (savedCart) {
    try {
      const parsedCart: CartItem[] = JSON.parse(savedCart);
      if (Array.isArray(parsedCart) && parsedCart.every(item => 'id' in item && 'name' in item && 'price' in item)) {
        return parsedCart;
      }
    } catch (e) {
      console.error('Error parsing cart data from localStorage', e);
    }
  }
  return [];
};

const Home: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [cart, setCart] = useState<CartItem[]>(loadCartFromLocalStorage());
  const [cartItemCount, setCartItemCount] = useState<number>(cart.reduce((total, item) => total + item.quantity, 0));
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);

  // Fetch products from API
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    axios.get('https://warunggo.bisalewat.online/api/products', {
      headers: {
        'Authorization': `Bearer ${token}`, // Sertakan token dalam header Authorization
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        setError('Error fetching products');
      });
  }, []);

  // Save cart to localStorage whenever cart state changes
  useEffect(() => {
    try {
      localStorage.setItem('cart', JSON.stringify(cart));
    } catch (e) {
      console.error('Error saving cart data to localStorage', e);
    }
  }, [cart]);

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setIsSearching(query.length > 0);
  };

  const handleAddToCart = (productId: string, quantity: number) => {
    const product = products.find(p => p.id === productId);
    if (product) {
      setCart(prevCart => {
        const existingItemIndex = prevCart.findIndex(item => item.id === productId);
        const updatedCart = [...prevCart];
        if (existingItemIndex !== -1) {
          updatedCart[existingItemIndex].quantity += quantity;
        } else {
          updatedCart.push({ ...product, quantity });
        }
        setCartItemCount(updatedCart.reduce((total, item) => total + item.quantity, 0));
        return updatedCart;
      });
    }
  };

  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="flex flex-col min-h-screen pb-16">
      <div className="fixed top-0 left-0 right-0 bg-white shadow-lg z-50">
        <Header />
        <SearchBar onSearch={handleSearch} cartItemCount={cartItemCount} />
      </div>
      <div className="flex-grow p-4 mt-24">
        {!isSearching && <BannerSlider />}
        <h1 className="text-2xl font-bold mt-6 mb-4">Product Listings</h1>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {error && <div className="text-red-500">{error}</div>}
          {filteredProducts.length > 0 ? (
            filteredProducts.map(product => (
              <ProductCard
                key={product.id}
                id={product.id}
                name={product.name}
                price={product.price}
                discount_price={product.discount_price}
                image={product.image}
                onProductClick={() => handleProductClick(product)}
                onAddToCart={handleAddToCart}
              />
            ))
          ) : (
            <div className="text-center text-gray-500">No products found</div>
          )}
        </div>
      </div>
      {selectedProduct && (
        <ProductDetailModal
          product={selectedProduct}
          isOpen={!!selectedProduct}
          onClose={handleCloseModal}
          onAddToCart={handleAddToCart}
        />
      )}
      <Navbar />
    </div>
  );
};

export default Home;
