import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import OrderList from './pages/OrderList';
import Profile from './pages/Profile';
import UpdateProfile from './pages/UpdateProfile';
import Cart from './pages/Cart';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { isAuthenticated } from './utils/auth';

const App: React.FC = () => {
  const authenticated = isAuthenticated();

  return (
    <Routes>
      <Route
        path="/"
        element={<PrivateRoute element={<Home />} isAuthenticated={authenticated} />}
      />
      <Route
        path="/order-list"
        element={<PrivateRoute element={<OrderList />} isAuthenticated={authenticated} />}
      />
      <Route
        path="/profile"
        element={<PrivateRoute element={<Profile />} isAuthenticated={authenticated} />}
      />
      <Route
        path="/update-profile"
        element={<PrivateRoute element={<UpdateProfile />} isAuthenticated={authenticated} />}
      />
      <Route
        path="/cart"
        element={<PrivateRoute element={<Cart />} isAuthenticated={authenticated} />}
      />
      <Route
        path="/login"
        element={<PublicRoute element={<Login />} isAuthenticated={authenticated} />}
      />
      {/* Add other routes here */}
    </Routes>
  );
};

const RouterWrapper: React.FC = () => (
  <Router>
    <App />
  </Router>
);

export default RouterWrapper;
