import React, { useState } from 'react';

interface ProductDetailModalProps {
  product: {
    id: string;
    name: string;
    price: number;
    discount_price?: number;
    image: string;
    description: string;
  };
  isOpen: boolean;
  onClose: () => void;
  onAddToCart: (productId: string, quantity: number) => void;
}

const ProductDetailModal: React.FC<ProductDetailModalProps> = ({ product, isOpen, onClose, onAddToCart }) => {
  const [quantity, setQuantity] = useState<number>(1);

  const formatCurrency = (value: number) => {
    return 'Rp' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  if (!isOpen) return null;

  const handleAddToCart = () => {
    if (quantity > 0) {
      onAddToCart(product.id, quantity);
      onClose();
    }
  };

  const handleDecreaseQuantity = () => {
    setQuantity(prev => Math.max(prev - 1, 1)); // Ensure quantity does not go below 1
  };

  const handleIncreaseQuantity = () => {
    setQuantity(prev => prev + 1); // You may want to add a maximum limit here
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
  <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-auto my-6 overflow-auto max-h-full">
    <img src={`/images/product/${product.image}`} alt={product.name} className="w-full h-auto object-cover mb-4" />
    <h2 className="text-2xl font-bold mb-2">{product.name}</h2>
    <p className="text-lg text-gray-700 mb-2">{product.description}</p>
    <div className="grid grid-cols-2 gap-4 items-center">
      <div>
        <p className="text-sm font-bold text-gray-500 line-through">{formatCurrency(product.price)}</p>
        {product.discount_price && (
          <p className="text-base font-bold text-red-500">{formatCurrency(product.discount_price)}</p>
        )}
      </div>
      <div className="flex items-center justify-end">
        <button
          onClick={handleDecreaseQuantity}
          disabled={quantity <= 1}
          className="px-3 py-2 text-white bg-red-500 rounded-l-md"
        >
          -
        </button>
        <span className="px-4 py-2">{quantity}</span>
        <button
          onClick={handleIncreaseQuantity}
          className="px-3 py-2 text-white bg-red-500 rounded-r-md"
        >
          +
        </button>
      </div>
    </div>
    <div className="flex justify-between items-center mt-4">
      <button
        onClick={onClose}
        className="px-6 py-2 bg-gray-500 text-white rounded-md"
      >
        Close
      </button>
      <button
        onClick={handleAddToCart}
        className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 transition ease-in-out duration-300"
      >
        Add to Cart
      </button>
    </div>
  </div>
</div>

  );
};

export default ProductDetailModal;
