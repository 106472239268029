import React from 'react';

interface ProductCardProps {
  id: string;
  name: string;
  price: number;
  discount_price?: number;
  image: string;
  onProductClick: () => void;
  onAddToCart: (productId: string, quantity: number) => void;
}

const formatCurrency = (value: number) => {
  return 'Rp' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const ProductCard: React.FC<ProductCardProps> = ({ id, name, price, discount_price, image, onProductClick, onAddToCart }) => {
  return (
    <div 
      key={id} 
      className="border p-4 rounded shadow transform transition-transform duration-300 hover:scale-105 hover:shadow-lg cursor-pointer"
      onClick={onProductClick}
    >
      <img
        src={`/images/product/${image}`}
        alt={name}
        className="w-full h-auto object-cover mb-4 rounded"
      />
      <h2 className="text-base font-semibold">{name}</h2>
      <p className="text-sm font-bold text-gray-500 line-through">{formatCurrency(price)}</p>
      {discount_price && (
        <p className="text-base font-bold text-red-500">{formatCurrency(discount_price)}</p>
      )}
    </div>
  );
};

export default ProductCard;
