import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import { FaTrashAlt } from 'react-icons/fa'; // Import icon delete

interface CartItem {
  id: string;
  name: string;
  price: number;
  discount_price?: number;
  image: string;
  quantity: number;
}

const formatCurrency = (value: number) => {
  return 'Rp' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const Cart: React.FC = () => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<string>('COD');
  const [customerId, setCustomerId] = useState<string | null>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      try {
        const parsedCart: CartItem[] = JSON.parse(savedCart);
        if (Array.isArray(parsedCart)) {
          setCart(parsedCart);
        } else {
          console.error('Invalid cart data in localStorage');
        }
      } catch (e) {
        console.error('Error parsing cart data from localStorage', e);
      }
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('authToken'); // Asumsi token disimpan di localStorage setelah login

    fetch('https://warunggo.bisalewat.online/api/customers/session', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Sertakan token dalam header Authorization
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.customer_id) {
        setCustomerId(data.customer_id);
      } else {
        console.error('Customer ID not found');
      }
    })
    .catch((error) => {
      console.error('Error fetching customer ID:', error);
    });
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      try {
        localStorage.setItem('cart', JSON.stringify(cart));
      } catch (e) {
        console.error('Error saving cart data to localStorage', e);
      }
    }
  }, [cart]);

  const handleQuantityChange = (id: string, quantity: number) => {
    setCart(prevCart =>
      prevCart.map(item =>
        item.id === id ? { ...item, quantity } : item
      )
    );
  };

  const handleDeleteItem = (id: string) => {
    if (window.confirm('Anda yakin ingin menghapus produk ini dari keranjang?')) {
      setCart(prevCart => prevCart.filter(item => item.id !== id));
    }
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + (item.discount_price || item.price) * item.quantity, 0);
  };

  const handleCheckout = () => {
    const token = localStorage.getItem('authToken'); // Asumsi token disimpan di localStorage setelah login
    if (!customerId) {
      alert('Tidak dapat menemukan ID pelanggan, mohon coba lagi.');
      return;
    }

    if (cart.length === 0) {
        alert('Tidak ada produk dalam keranjang');
        return;
    }


    const isConfirmed = window.confirm("Apakah pesanan Anda sudah sesuai?");
    if (isConfirmed) {
      const order = {
        customer_id: customerId,
        items: cart.map(item => ({
          product_id: item.id,
          quantity: item.quantity,
          price: item.discount_price || item.price,
          total_price: (item.discount_price || item.price) * item.quantity
        })),
        total_amount: calculateTotal(),
        payment_method: paymentMethod,
        status: "Pending",
        tracking_status: "Belum diproses"
      };

      fetch('https://warunggo.bisalewat.online/api/orders/newOrder', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Sertakan token dalam header Authorization
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(order),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Order created:', data);
        alert('Pesanan berhasil dibuat!');
        setCart([]); // Kosongkan keranjang setelah checkout berhasil
        localStorage.removeItem('cart'); // Hapus data keranjang dari localStorage
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Terjadi kesalahan saat membuat pesanan.');
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen pb-10">
      <Header />
      <div className="flex-grow p-4">
        <h1 className="text-2xl font-bold mb-4">Shopping Cart</h1>
        <div className="bg-white shadow-md rounded-lg">
          {cart.map(item => (
            <div key={item.id} className="border-b p-4 grid grid-cols-2 gap-4 items-start">
              <div>
                <div className="font-semibold text-lg">{item.name}</div>
                <div className="text-sm text-gray-500 line-through">{formatCurrency(item.price)}</div>
                {item.discount_price && (
                  <div className="text-sm text-red-500">{formatCurrency(item.discount_price)}</div>
                )}
              </div>
              <div className="text-right">
                <div className="font-semibold text-lg">
                  {formatCurrency((item.discount_price || item.price) * item.quantity)}
                </div>
                <div className="flex items-center justify-end space-x-2 mt-2">
                  <button
                    onClick={() => handleQuantityChange(item.id, Math.max(item.quantity - 1, 1))}
                    className="px-2 py-1 text-white bg-red-500 rounded-l-md text-sm"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={item.quantity}
                    min="1"
                    className="w-12 p-1 text-center border rounded text-sm"
                    onChange={(e) => handleQuantityChange(item.id, Number(e.target.value))}
                  />
                  <button
                    onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                    className="px-2 py-1 text-white bg-red-500 rounded-r-md text-sm"
                  >
                    +
                  </button>
                  <button
                    onClick={() => handleDeleteItem(item.id)}
                    className="p-1 text-red-500 hover:text-red-700"
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="p-4 border-t flex justify-between font-bold text-lg">
            <span>Total</span>
            <span>{formatCurrency(calculateTotal())}</span>
          </div>
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-2">Pengiriman</h2>
            <p>Dikirim langsung</p>
          </div>
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-2">Metode Pembayaran</h2>
            <div className="flex items-center space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  value="COD"
                  checked={paymentMethod === 'COD'}
                  onChange={() => setPaymentMethod('COD')}
                  className="form-radio"
                />
                <span className="ml-2">Cash on Delivery (COD)</span>
              </label>
            </div>
          </div>
          <div className="p-4 mt-4">
            <button
              onClick={handleCheckout}
              className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 transition ease-in-out duration-300"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default Cart;
